@import "./color-codes.scss";

$font-family_1: "Segoe UI", Frutiger, "Frutiger Linotype", "Dejavu Sans", Arial, sans-serif;
$font-family_2: inherit;
$font-family_3: Roboto, "Helvetica Neue", sans-serif;

$percent_100: 100%;
$percent_50: 50%;

.W-A {
	width: auto !important;
}
.fullHW {
	width: 100%;
	height: 100%;
}
.W-100P {
	width: 100% !important;
}
.W-80 {
	width: 80px;
}
.W-70 {
	width: 70px !important;
}
.W-60 {
	width: 60px !important;
}
.W-50 {
	width: 50px !important;
}
.W-20 {
	width: 20px !important;
}
.H-50 {
	height: 50px !important;
}
.H-27 {
	height: 27px !important;
}
.H-30 {
	height: 30px !important;
}
.H-35 {
	height: 35px !important;
}
.H-40 {
	height: 40px !important;
}
.H-20 {
	height: 20px !important;
}
.FW-B {
	font-weight: bold;
}
.FW-500 {
	font-weight: 500;
}
.FW-600 {
	font-weight: 600;
}
.FW-700 {
	font-weight: 700;
}
.W-70 {
	width: 70px;
}
.W-300 {
	width: 300px;
}
.W-250 {
	width: 250px;
}
.W-200 {
	width: 200px;
}
.W-150 {
	width: 150px;
}
.W-500 {
	width: 500px;
}
.W-510 {
	width: 510px;
}
.W-710 {
	width: 710px;
}
.BR_20P {
	border-radius: 20px;
}
.FS-14P {
	font-size: 14px;
}
.B_solidP2 {
	border: 0.2px solid #e1e1e1;
}
.F_R {
	float: right;
}
.F_L {
	float: left;
}
.D_B {
	display: block !important;
}
.D_IB {
	display: inline-block !important;
}
.font-size10 {
	font-size: 10px;
}
.M-T30P {
	margin-top: 30px;
}
.WM-50 {
	max-width: $percent_50;
}
.TA_L {
	text-align: left !important;
}
.TA_R {
	text-align: right;
}
.TA_C {
	text-align: center !important;
}
.TA_E {
	text-align: end;
}
.AI_C {
	align-items: center;
}
.AC_C {
	align-content: center;
}
.td-underline {
	text-decoration: underline !important;
}
.D-B {
	display: block;
}
.BS_cover {
	background-size: cover;
}
.D-F {
	display: flex;
}
.JC_SB {
	justify-content: space-between;
}
.JC_SE {
	justify-content: space-evenly;
}
.JC_SA {
	justify-content: space-around;
}
.FD_R {
	flex-direction: row;
}
.JC_C {
	justify-content: center;
}
.JC_L {
	justify-content: left;
}
.PO_R {
	position: relative !important;
}
.W-unset {
	width: unset !important;
	width: unset;
}
.T_AC {
	text-align: center;
}
.C_D {
	cursor: default;
}
.W-200 {
	width: 200px !important;
}
.W-80 {
	width: 80px !important;
}
.W-70 {
	width: 70px !important;
}
.W-90 {
	width: 90px !important;
}
.W-99 {
	width: 99% !important;
}
.W-100 {
	width: 100px !important;
}
.W-115 {
	width: 115px !important;
}
.W-150 {
	width: 150px;
}
.W-150 {
	width: 150px !important;
}
.W-180 {
	width: 180px !important;
}
.W-110 {
	width: 110px;
}
.w950px {
	width: 950px;
}
.w900px {
	width: 900px;
}
.w800px {
	width: 800px;
}
.w600px {
	width: 600px;
}
.w700px {
	width: 700px;
}
.w600px {
	width: 600px;
}
.w500px {
	width: 500px;
}
.w400px {
	width: 400px;
}
.w450px {
	width: 450px;
}
.w350px {
	width: 350px;
}
.w300px {
	width: 300px;
}
.w250px {
	width: 250px;
}
.w280px {
	width: 280px;
}
.w200px {
	width: 200px;
}
.w185px {
	width: 185px !important;
}
.w150px {
	width: 150px;
}
.w100px {
	width: 100px;
}

.H-400 {
	height: 400px;
}
.H-450 {
	height: 450px;
}
.W-90P {
	width: 90%;
}
.padding0 {
	padding-right: 0px;
	padding-left: 0px;
}
.ML-30M {
	margin-left: -30px !important;
}
.ML-35M {
	margin-left: -35px !important;
}
.ML-20M {
	margin-left: -20px !important;
}
.ML-10M {
	margin-left: -10px !important;
}
.ML-0 {
	margin-left: 0px !important;
}
.MT-3 {
	margin-top: 3px;
}
.MT-5M {
	margin-top: -5px;
}
.MT-5 {
	margin-top: 5px;
}
.MT-20M {
	margin-top: -20px;
}
.MT-10M {
	margin-top: -10px;
}
.MT-10 {
	margin-top: 10px !important;
}
.MT-12 {
	margin-top: 12px !important;
}
.MT-13 {
	margin-top: 13px;
}
.MT-15 {
	margin-top: 15px !important;
}
.MT-20 {
	margin-top: 20px;
}
.MT-30 {
	margin-top: 30px;
}
.MT-35 {
	margin-top: 35px;
}
.MB-20 {
	margin-bottom: 20px !important;
}
.P-8 {
	padding: 8px !important;
}
.P-10 {
	padding: 10px;
}
.PL-50 {
	padding-left: 50px !important;
}
.P-15 {
	padding: 15px;
}
.P-20 {
	padding: 20px;
}
.P-0 {
	padding: 0 !important;
}
.M-0 {
	margin: 0 !important;
}
.ML-1P {
	margin-left: 1% !important;
}
.ML-2P {
	margin-left: 2% !important;
}
.ML-100M {
	margin-left: -100px;
}
.ML-50M {
	margin-left: -50px;
}
.ML-15 {
	margin-left: 15px;
}
.M-5 {
	margin: 5px !important;
}
.PT-2 {
	padding-top: 2px;
}
.PL-15 {
	padding-left: 15px;
}
.MR-5 {
	margin-right: 5px;
}
.ML-10 {
	margin-left: 10px;
}
.ML-15 {
	margin-left: 15px !important;
}
.ML-18 {
	margin-left: 18px !important;
}
.ML-20 {
	margin-left: 20px;
}
.MT-8 {
	margin-top: 8px;
}
.MT-2 {
	margin-top: 2px;
}
.MR-2 {
	margin-right: 2px;
}
.PL-0 {
	padding-left: 0px !important;
}
.PL-7 {
	padding-left: 7px !important;
}
.PL-10 {
	padding-left: 10px !important;
}
.PL-12 {
	padding-left: 12px !important;
}
.PL-20 {
	padding-left: 20px;
}
.PR-0 {
	padding-right: 0px;
}
.PT-0 {
	padding-top: 0px;
}
.PB-0 {
	padding-bottom: 0px;
}
.PT-5 {
	padding-top: 5px;
}
.PT-4 {
	padding-top: 4px;
}
.PR-5 {
	padding-right: 5px;
}
.PR-10 {
	padding-right: 10px;
}
.PR-20 {
	padding-right: 20px;
}
.PR-30 {
	padding-right: 30px;
}
.P-5 {
	padding: 5px !important;
}
.PT-5 {
	padding-top: 5px;
}
.ML-25 {
	margin-left: 25px !important;
}
.ML-30 {
	margin-left: 30px;
}
.ML-35 {
	margin-left: 35px;
}
.ML-32 {
	margin-left: 32px !important;
}
.ML-40 {
	margin-left: 40px;
}
.ML-50 {
	margin-left: 50px;
}
.MR-10 {
	margin-right: 10px;
}
.MB-5 {
	margin-bottom: 5px;
}
.MB-10 {
	margin-bottom: 10px;
}
.MR-20 {
	margin-right: 20px;
}
.ML-20 {
	margin-left: 20px;
}
.PL-20 {
	padding-left: 20px;
}
.PR-40 {
	padding-right: 40px;
}
.PR-0 {
	padding-right: 0px;
}
.PT-20 {
	padding-top: 20px;
}
.PT-10 {
	padding-top: 15px;
}
.PT-15 {
	padding-top: 10px;
}
.PB-10 {
	padding-bottom: 10px;
}
.PB-20 {
	padding-bottom: 20px;
}
.pullRight {
	float: right;
}
.textareaHeight {
	height: 100px;
}
.disabled {
	color: #999 !important;
	cursor: not-allowed !important;
	pointer-events: auto !important;
}
button {
	&:disabled {
		border: 1px solid $lavenderPinocchio_color;
		background: $greenWhite_color;
		cursor: no-drop;
		color: #a1a1a1;
		span {
			cursor: no-drop;
		}
		label {
			cursor: no-drop;
		}
	}
}
a.disabled {
	color: gray;
	cursor: not-allowed;
	text-decoration: underline;
}

::-webkit-scrollbar {
	width: 10px;
}
::-webkit-scrollbar-track {
	background-color: $white_color;
	-webkit-border-radius: 15px;
	border-radius: 15px;
	box-shadow: inset 0 0 5px $grey_color;
}
::-webkit-scrollbar-thumb {
	-webkit-border-radius: 15px;
	border-radius: 15px;
	border: 3px solid transparent;
	background-clip: content-box;
	background-color: $cottonSeed_color;
	border-radius: 4px;
}
.template_active {
	color: $green_color;
}
.template_inactive {
	color: $red_color;
}
.template_done {
	color: $boulder_color;
}
.tabActive {
	background-color: #01579b !important;
	color: white;
}
input:disabled {
	background: #ccc;
}
.successAlert {
	width: auto;
	position: absolute;
	top: 12%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
	padding: 25px 50px;
	font-size: 1.25em;
	z-index: 9999;
}
.dangerAlert {
	width: auto;
	position: absolute;
	top: 12%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	text-align: center;
	padding: 25px 50px;
	font-size: 1.25em;
	z-index: 9999;
}
.required {
	color: $red_color;
}
.cls_successlabel_backColor {
	background-color: $success_color !important;
}
.cls_successlabel_color {
	color: $success_color !important;
}
.cls_warninglabel_backColor {
	background-color: $warning_color !important;
}
.cls_warnninglabel_color {
	color: $warning_color !important;
}
.cls_infolabel_backcolor {
	background-color: $info_color !important;
}
.cls_infolabel_color {
	color: $info_color !important;
}
.cls_primarylabel_backcolor {
	background-color: $primary_color !important;
}
.cls_primarylabel_color {
	color: $primary_color !important;
}
.cls_greenbackcolor {
	background-color: $green_color !important;
}
.cls_bluebackcolor {
	background-color: $yaleBlue_color !important;
}
.PA {
	position: absolute;
}
.PR {
	position: relative;
}
.PF {
	position: fixed;
}
.H-300 {
	height: 300px;
}
.removeUnderlineAndCursor {
	cursor: auto !important;
	text-decoration: underline !important;
}
.removeCursor {
	cursor: auto !important;
}
.bg-white {
	background-color: $white_color;
}
.checkboxView {
	height: 20px;
	width: 20px;
	margin-left: 20px !important;
}
.W-10P {
	width: 10%;
}
.W-15P {
	width: 15%;
}
.W-20P {
	width: 20%;
}
.W-60P {
	width: 60%;
}
.ML-35M {
	margin-left: -35px;
}
.ML-30M {
	margin-left: -30px;
}
.ML-20M {
	margin-left: -20px;
}
.ML-10M {
	margin-left: -10px;
}

.mt-1rem {
	margin-top: 1rem;
}
.mb-1rem {
	margin-bottom: 1rem;
}
.ml-1rem {
	margin-left: 1rem;
}
.mr-1rem {
	margin-right: 1rem;
}

.p-p5 {
	padding: 0.5rem;
}
.w-64 {
	width: 64%;
}
.H-36P {
	height: 36%;
}
.w-10 {
	width: 10%;
}
.w-9 {
	width: 9%;
}
.w-8 {
	width: 8%;
}
.w-6 {
	width: 6%;
}
.w-5 {
	width: 5%;
}
.w-7 {
	width: 7%;
}
.mt-p5 {
	margin-top: 0.5rem;
}
.mt-p1 {
	margin-top: 0.1rem;
}
.mt-p2 {
	margin-top: 0.2rem;
}
.mt-1 {
	margin-top: 1rem !important;
}
.mb-p5 {
	margin-bottom: 0.5rem !important;
}
.mb-1 {
	margin-bottom: 1rem !important;
}
.ml-1 {
	margin-left: 1rem !important;
}
.mr-1 {
	margin-right: 1rem !important;
}
.mt-2 {
	margin-top: 2rem !important;
}
.mb-2 {
	margin-bottom: 2rem !important;
}
.ml-2 {
	margin-left: 2rem !important;
}
.mr-2 {
	margin-right: 2rem !important;
}
.mt-3 {
	margin-top: 3rem !important;
}
.mb-3 {
	margin-bottom: 3rem !important;
}
.ml-3 {
	margin-left: 3rem !important;
}
.mr-3 {
	margin-right: 3rem !important;
}
.mt-4 {
	margin-top: 4rem !important;
}
.mb-4 {
	margin-bottom: 4rem !important;
}
.ml-4 {
	margin-left: 4rem !important;
}
.mr-4 {
	margin-right: 4rem !important;
}
.pl-1 {
	padding-left: 1rem;
}
.pr-1 {
	padding-right: 1rem;
}
.pl-2 {
	padding-left: 2rem;
}
.pr-2 {
	padding-right: 2rem;
}
.pr-2p5 {
	padding-right: 2.5rem;
}
.pt-1 {
	padding-top: 1rem;
}
.pb-1 {
	padding-bottom: 1rem;
}
.pt-2 {
	padding-top: 2rem;
}
.pb-2 {
	padding-bottom: 2rem;
}
.border-radius-4 {
	border-radius: 4px;
}
.border-solid {
	border: 1px solid rgba(154, 154, 154, 0.4509803922);
}
.border1 {
	border: 1px solid;
	border-radius: 4px;
	padding: 4px;
	padding: 8px;
}
.textwarp-no {
	text-wrap: nowrap;
}
.w-40 {
	width: 40%;
}
.w-45 {
	width: 45%;
}
.w-50 {
	width: 50%;
}
.w-60 {
	width: 60%;
}
.w-65 {
	width: 65%;
}
.w-70 {
	width: 70%;
}
.w-75 {
	width: 75%;
}
.w-80 {
	width: 80%;
}
.w-85 {
	width: 85%;
}
.w-90 {
	width: 90%;
}
.w-35 {
	width: 35%;
}
.w-45 {
	width: 45%;
}
.w-30 {
	width: 30%;
}
.w-28 {
	width: 28%;
}
.w-26 {
	width: 26%;
}
.w-20 {
	width: 20%;
}
.w-15 {
	width: 15%;
}
.w-16 {
	width: 16%;
}
.w-10 {
	width: 10%;
}
.w-5 {
	width: 5%;
}
.mb-5rem {
	margin-bottom: 5rem;
}
.pt-p3 {
	padding-top: .3rem;
}
.pb-p3 {
	padding-bottom: .3rem;
}
.pt-p4 {
	padding-top: .4rem;
}
.pb-p4 {
	padding-bottom: .4rem;
}
.pt-p5 {
	padding-top: .5rem;
}
.pb-p5 {
	padding-bottom: .5rem;
}
.pl-p1 {
	padding-left: 0.1rem;
}
.ml-p1 {
	margin-left: 0.1rem;
}
.pl-p2 {
	padding-left: 0.2rem;
}
.ml-p2 {
	margin-left: 0.2rem;
}
.pl-p3 {
	padding-left: 0.3rem;
}
.ml-p3 {
	margin-left: 0.3rem;
}
.pl-p4 {
	padding-left: 0.4rem;
}
.ml-p4 {
	margin-left: 0.4rem;
}
.mr-p5{
	margin-right: 0.5rem;
}
.mt-p4 {
	margin-top: 0.4rem;
}
.mt-p5{
	margin-top: 0.5rem;
}