.mat-button-toggle-group {
	&__small {
		.mat-button-toggle {
			.mat-button-toggle-label-content {
				line-height: 30px;
			}
		}
	}

	&__selection-underline {
		.mat-button-toggle.mat-button-toggle-checked {
			.mat-button-toggle-label-content {
				text-decoration: underline;
			}
		}
	}
}
