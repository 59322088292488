a {
	text-decoration: none;
	text-decoration: none !important;
	cursor: pointer;
}
select,
option {
	cursor: pointer !important;
}
textarea {
	resize: none !important;
}
select {
	border-radius: 0;
	// margin-left: 20px;
	outline: 1px inset #0387d1;
	outline-offset: -1px;
	border-radius: 0px;
	outline: 0px $downriver_color;
	&:focus {
		outline: $downriver_color c1 auto 5px;
	}
}
li {
	list-style: none;
}
button {
	&:hover {
		-ms-transform: scale(1.01) !important;
		-webkit-transform: scale(1.01) !important;
		transform: scale(1.01) !important;
	}
}
.ngx-pagination {
	font-size: 16px;
	height: 16px;
	.current {
		background: #969494 !important;
	}
}
fieldset[disabled] {
	.btn-success {
		&:hover {
			color: $white_color;
			background-color: $downriver_color;
			border-color: $downriver_color;
		}
		&:focus {
			color: $white_color;
			background-color: $downriver_color;
			border-color: $downriver_color;
		}
	}
	.btn-success.focus {
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
}
.open {
	> .dropdown-toggle.btn-success {
		&:hover {
			color: $white_color;
			background-color: $downriver_color;
			border-color: $downriver_color;
		}
		&:focus {
			color: $white_color;
			background-color: $downriver_color;
			border-color: $downriver_color;
		}
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
	> .dropdown-toggle.btn-success.focus {
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
}
@media (min-width: 564px) {
	.daterangepicker {
		width: auto;
		.ranges {
			ul {
				width: 160px;
			}
		}
	}
	.daterangepicker.single {
		.ranges {
			ul {
				width: $percent_100;
			}
		}
		.calendar.left {
			clear: none;
		}
	}
	.daterangepicker.single.ltr {
		.ranges {
			float: left;
		}
		.calendar {
			float: left;
		}
	}
	.daterangepicker.single.rtl {
		.ranges {
			float: right;
		}
		.calendar {
			float: right;
		}
	}
	.daterangepicker.ltr {
		direction: ltr;
		text-align: left;
		.calendar.left {
			clear: left;
			margin-right: 0;
			.calendar-table {
				border-right: none;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
				padding-right: 12px;
			}
		}
		.calendar.right {
			margin-left: 0;
			.calendar-table {
				border-left: none;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
		.left {
			.daterangepicker_input {
				padding-right: 12px;
			}
		}
		.ranges {
			float: left;
		}
		.calendar {
			float: left;
		}
	}
	.daterangepicker.rtl {
		direction: rtl;
		text-align: right;
		.calendar.left {
			clear: right;
			margin-left: 0;
			.calendar-table {
				border-left: none;
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
				padding-left: 12px;
			}
		}
		.calendar.right {
			margin-right: 0;
			.calendar-table {
				border-right: none;
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		.left {
			.daterangepicker_input {
				padding-left: 12px;
			}
		}
		.ranges {
			text-align: right;
			float: right;
		}
		.calendar {
			text-align: right;
			float: right;
		}
	}
}
@media (min-width: 730px) {
	.daterangepicker {
		.ranges {
			width: auto;
		}
		.calendar.left {
			clear: none !important;
		}
	}
	.daterangepicker.ltr {
		.ranges {
			float: left;
		}
	}
	.daterangepicker.rtl {
		.ranges {
			float: right;
		}
	}
}
.checkbox {
	padding-left: 2px;
	label {
		display: inline-block;
		vertical-align: middle;
		position: relative;
		padding-left: 0.5rem;
		&::before {
			content: "";
			display: inline-block;
			position: absolute;
			width: 20px;
			height: 20px;
			left: 0;
			margin-left: -20px;
			border: 1px solid #cccccc;
			border-radius: 3px;
			background-color: $white_color;
			transition:
				border 0.15s ease-in-out,
				color 0.15s ease-in-out;
		}
		&::after {
			display: inline-block;
			position: absolute;
			width: 20px;
			height: 20px;
			left: 0;
			top: 0;
			margin-left: -20px;
			text-align: center;
			font-size: 11px;
			color: $davyGrey_color;
		}
	}
	input[type="checkbox"] {
		opacity: 0;
		z-index: 1;
		height: 20px;
		width: 20px;
		outline: none;
		cursor: pointer;
		&:focus {
			+ {
				label {
					&::before {
						outline: thin dotted;
						outline: 5px auto -webkit-focus-ring-color;
						outline-offset: -2px;
						outline: none;
						cursor: pointer;
					}
				}
			}
		}
		&:checked {
			+ {
				label {
					&::after {
						content: "\2713";
						font-size: 15px;
						top: -3px;
					}
				}
			}
		}
		&:disabled {
			+ {
				label {
					opacity: 0.65;
					&::before {
						background-color: $grey_color;
						cursor: not-allowed;
					}
				}
			}
		}
	}
}
.checkbox.checkbox-circle {
	label {
		&::before {
			border-radius: $percent_50;
			outline: none;
		}
	}
}
.checkbox.checkbox-inline {
	margin-top: 0;
	outline: none;
	cursor: pointer;
}
.checkbox-primary {
	input[type="checkbox"] {
		&:checked {
			+ {
				label {
					&::before {
						background-color: $azure_color;
						border-color: $azure_color;
					}
					&::after {
						color: $white_color;
					}
				}
			}
		}
	}
}
.checkbox-editable {
	input[type="checkbox"] {
		&:checked {
			+ {
				label {
					&::before {
						background-color: $vividTangerine_color;
						border-color: $vividTangerine_color;
						outline: none;
					}
					&::after {
						color: $white_color;
						outline: none;
					}
				}
			}
		}
	}
}
.checkbox-non-editable {
	input[type="checkbox"] {
		&:checked {
			+ {
				label {
					&::before {
						background-color: $moonstoneBlue_color;
						border-color: $moonstoneBlue_color;
						outline: none;
					}
					&::after {
						color: $white_color;
						outline: none;
					}
				}
			}
		}
	}
}
.checkbox-danger {
	input[type="checkbox"] {
		&:checked {
			+ {
				label {
					&::before {
						background-color: $paleRed_color;
						border-color: $paleRed_color;
					}
					&::after {
						color: $white_color;
					}
				}
			}
		}
	}
}
.checkbox-info {
	input[type="checkbox"] {
		&:checked {
			+ {
				label {
					&::before {
						background-color: $jellyfish_color;
						border-color: $black_color;
					}
					&::after {
						color: $white_color;
					}
				}
			}
		}
	}
}
.checkbox-warning {
	input[type="checkbox"] {
		&:checked {
			+ {
				label {
					&::before {
						background-color: $lightOrange_color;
						border-color: $lightOrange_color;
					}
					&::after {
						color: $white_color;
					}
				}
			}
		}
	}
}
.checkbox-success {
	input[type="checkbox"] {
		&:checked {
			+ {
				label {
					&::before {
						background-color: $downriver_color;
						border-color: $downriver_color;
					}
					&::after {
						color: $white_color;
					}
				}
			}
		}
	}
}
.daterangepicker {
	position: absolute;
	color: $inherit_color;
	background: $white_color;
	border-radius: 4px;
	padding: 4px;
	margin-top: 1px;
	top: 100px;
	left: 20px;
	&:before {
		position: absolute;
		display: inline-block;
		border-bottom-color: $black_2_color;
		content: "";
		top: -7px;
		border-right: 7px solid transparent;
		border-left: 7px solid transparent;
		border-bottom: 7px solid $pastel_Grey;
	}
	&:after {
		position: absolute;
		display: inline-block;
		border-bottom-color: $black_2_color;
		content: "";
		top: -6px;
		border-right: 6px solid transparent;
		border-bottom: 6px solid $white_color;
		border-left: 6px solid transparent;
	}
	.calendar {
		display: none;
		max-width: 270px;
		margin: 4px;
		th {
			white-space: nowrap;
			text-align: center;
			min-width: 32px;
		}
		td {
			white-space: nowrap;
			text-align: center;
			min-width: 32px;
		}
	}
	.calendar.single {
		.calendar-table {
			border: none;
		}
	}
	.calendar-table {
		border: 1px solid $white_color;
		padding: 4px;
		border-radius: 4px;
		background: $white_color;
	}
	table {
		width: $percent_100;
		margin: 0;
	}
	td {
		text-align: center;
		width: 20px;
		height: 20px;
		border-radius: 4px;
		border: 1px solid transparent;
		white-space: nowrap;
		cursor: pointer;
	}
	th {
		text-align: center;
		width: 20px;
		height: 20px;
		border-radius: 4px;
		border: 1px solid transparent;
		white-space: nowrap;
		cursor: pointer;
	}
	td.available {
		&:hover {
			background-color: $soft_Peach;
			border-color: $color_transparent;
			color: $inherit_color;
		}
	}
	th.available {
		&:hover {
			background-color: $soft_Peach;
			border-color: $color_transparent;
			color: $inherit_color;
		}
	}
	td.week {
		font-size: 80%;
		color: $pastel_Grey;
	}
	th.week {
		font-size: 80%;
		color: $pastel_Grey;
	}
	td.off {
		background-color: $white_color;
		border-color: $color_transparent;
		color: $lemon_Grass;
	}
	td.off.in-range {
		background-color: $white_color;
		border-color: $color_transparent;
		color: $lemon_Grass;
	}
	td.off.start-date {
		background-color: $white_color;
		border-color: $color_transparent;
		color: $lemon_Grass;
	}
	td.off.end-date {
		background-color: $white_color;
		border-color: $color_transparent;
		color: $lemon_Grass;
	}
	td.in-range {
		background-color: $downriver_color;
		border-color: $color_transparent;
		color: $white_color;
		border-radius: 0;
	}
	td.start-date {
		border-radius: 4px 0 0 4px;
	}
	td.end-date {
		border-radius: 0 4px 4px 0;
	}
	td.start-date.end-date {
		border-radius: 4px;
	}
	td.active {
		background-color: $downriver_color;
		border-color: $color_transparent;
		color: $white_color;
		&:hover {
			background-color: $downriver_color;
			border-color: $color_transparent;
			color: $white_color;
		}
	}
	th.month {
		width: auto;
	}
	td.disabled {
		color: $lemon_Grass;
		cursor: not-allowed;
		text-decoration: line-through;
	}
	option.disabled {
		color: $lemon_Grass;
		cursor: not-allowed;
		text-decoration: line-through;
	}
	select.monthselect {
		font-size: 12px;
		padding: 1px;
		height: auto;
		margin: 0;
		cursor: default;
		margin-right: 2%;
		width: 56%;
	}
	select.yearselect {
		font-size: 12px;
		padding: 1px;
		height: auto;
		margin: 0;
		cursor: default;
		width: 40%;
	}
	select.hourselect {
		width: 50px;
		margin-bottom: 0;
	}
	select.minuteselect {
		width: 50px;
		margin-bottom: 0;
	}
	select.secondselect {
		width: 50px;
		margin-bottom: 0;
	}
	select.ampmselect {
		width: 50px;
		margin-bottom: 0;
	}
	.input-mini {
		border: 1px solid $pastel_Grey;
		border-radius: 4px;
		color: $davyGrey_color;
		height: 30px;
		line-height: 30px;
		display: block;
		vertical-align: middle;
		margin: 0 0 5px 0;
		padding: 0 6px 0 28px;
		width: $percent_100;
	}
	.input-mini.active {
		border: 1px solid $downriver_color;
		border-radius: 4px;
	}
	.daterangepicker_input {
		position: relative;
		i {
			position: absolute;
			left: 8px;
			top: 8px;
		}
	}
	.calendar-time {
		text-align: center;
		margin: 5px auto;
		line-height: 30px;
		position: relative;
		padding-left: 28px;
		select.disabled {
			color: $pastel_Grey;
			cursor: not-allowed;
		}
	}
	.btn-primary,
	.btn-primary:hover {
		color: #fff;
		background-color: $downriver_color !important;
		border-color: $downriver_color !important;
	}
}
.daterangepicker.opensleft {
	&:before {
		right: 9px;
	}
	&:after {
		right: 10px;
	}
}
.daterangepicker.openscenter {
	&:before {
		left: 0;
		right: 0;
		width: 0;
		margin-left: auto;
		margin-right: auto;
	}
	&:after {
		left: 0;
		right: 0;
		width: 0;
		margin-left: auto;
		margin-right: auto;
	}
}
.daterangepicker.opensright {
	&:before {
		left: 9px;
	}
	&:after {
		left: 10px;
	}
}
.daterangepicker.dropup {
	margin-top: -5px;
	&:before {
		top: initial;
		bottom: -7px;
		border-bottom: initial;
		border-top: 7px solid $pastel_Grey;
	}
	&:after {
		top: initial;
		bottom: -6px;
		border-bottom: initial;
		border-top: 6px solid $white_color;
	}
}
.daterangepicker.dropdown-menu {
	max-width: none;
	z-index: 3001;
}
.daterangepicker.single {
	.ranges {
		float: none;
	}
	.calendar {
		float: none;
	}
}
.daterangepicker.show-calendar {
	.calendar {
		display: block;
	}
}
.daterangepicker.rtl {
	.input-mini {
		padding-right: 28px;
		padding-left: 6px;
	}
	.daterangepicker_input {
		i {
			left: auto;
			right: 8px;
		}
	}
}
.ranges {
	font-size: 11px;
	float: none;
	margin: 4px;
	text-align: left;
	ul {
		list-style: none;
		margin: 0 auto;
		padding: 0;
		width: $percent_100;
	}
	li {
		font-size: 13px;
		background: $whiteSmoke_color;
		border: 1px solid $whiteSmoke_color;
		border-radius: 4px;
		color: $downriver_color;
		padding: 3px 12px;
		margin-bottom: 8px;
		cursor: pointer;
		&:hover {
			background: $downriver_color !important;
			border: 1px solid $downriver_color !important;
			color: $white_color;
		}
	}
	li.active {
		background: $downriver_color !important;
		border: 1px solid $downriver_color !important;
		color: $white_color !important;
	}
}
.btn-success {
	color: $white_color;
	background-color: $downriver_color;
	border-color: $downriver_color;
	&:hover {
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
	&:active {
		&:hover {
			color: $white_color;
			background-color: $downriver_color;
			border-color: $downriver_color;
		}
		&:focus {
			color: $white_color;
			background-color: $downriver_color;
			border-color: $downriver_color;
		}
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
	&:active.focus {
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
	&:focus {
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
}
.btn-success.active {
	&:hover {
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
	&:focus {
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
	color: $white_color;
	background-color: $downriver_color;
	border-color: $downriver_color;
}
.btn-success.active.focus {
	color: $white_color;
	background-color: $downriver_color;
	border-color: $downriver_color;
}
.btn-success.focus {
	color: $white_color;
	background-color: $downriver_color;
	border-color: $downriver_color;
}
.btn-success.disabled {
	&:hover {
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
	&:focus {
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
}
.btn-success[disabled] {
	&:hover {
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
	&:focus {
		color: $white_color;
		background-color: $downriver_color;
		border-color: $downriver_color;
	}
}
.btn-success.disabled.focus {
	color: $white_color;
	background-color: $downriver_color;
	border-color: $downriver_color;
}
.btn-success[disabled].focus {
	color: $white_color;
	background-color: $downriver_color;
	border-color: $downriver_color;
}
.atlwdg-trigger.atlwdg-RIGHT {
	left: 0% !important;
	top: unset !important;
	bottom: 0%;
	-webkit-transform: rotate(0deg) !important;
	-webkit-transform-origin: top left !important;
	-moz-transform: rotate(0deg) !important;
	-moz-transform-origin: top left !important;
	-ms-transform: rotate(0deg) !important;
	-ms-transform-origin: top left !important;
	transform: rotate(0deg) !important;
	transform-origin: top left !important;
}
.atlwdg-trigger {
	position: fixed !important;
	background: $white_color !important;
	padding: 5px 10px !important;
	border: 2px solid $white_color !important;
	border-top: 2px solid $white_color !important;
	border-bottom: none !important;
	font-weight: bold !important;
	color: $azure_color !important;
	display: block !important;
	white-space: nowrap !important;
	text-decoration: none !important;
	font-family: $font-family_2 !important;
	font-size: 12px !important;
	-webkit-box-shadow: 0 0 0px rgba(0, 0, 0, 0.5) !important;
	box-shadow: 0 0 0px rgba(0, 0, 0, 0.5) !important;
	border-radius: 0px 0px 0px 0px !important;
}
#atlwdg-trigger {
	color: $black_color !important;
	font-size: 16px !important;
	width: 16%;
	text-align: center;
}
.vertical-space {
	height: 30px;
}
.c-btn {
	font-size: 14px !important;
	box-shadow: 0px 1px 5px $mountainMist;
	padding: 5px 10px !important;
}
.clear-all {
	display: none !important;
}
.canvas-style {
	border-radius: 18px;
	border-top: 10px solid $lightgray_color;
	border-bottom: 1px solid $lightgray_color;
	border-left: 1px solid $lightgray_color;
	border-right: 1px solid $lightgray_color;
	margin: 8px;
	background: white;
	padding: 2px;
	box-shadow: 0px 1px 5px $mountainMist;
}
.clslanguage {
	li.pure-checkbox {
		display: flex !important;
	}
	.pure-checkbox {
		input[type="checkbox"] + label {
			padding-left: 0em;
		}
	}
}
.heightCalculate {
	height: calc(100vh - 100px);
}
.cls_dropdownsearchinput {
	border: none !important;
	width: $percent_100 !important;
	height: $percent_100 !important;
	outline: none !important;
}
.list-filter .c-clear {
	align-content: center;
	cursor: pointer;
}
.list-filter .c-search {
	align-content: center;
	// cursor: pointer;
}
.switch.switch-medium {
	height: 25px;
}
.switch.switch-medium small {
	width: 25px;
	height: 25px;
	right: calc(100% - 25px);
}
.switch.checked small {
	right: 0 !important;
	left: auto;
}

.modal-body {
	min-height: 20vh;
	max-height: 70vh;
	overflow-y: auto;
	padding: 0px 20px;
}
.modal-container {
	min-height: calc(100vh - 150px);
	max-height: calc(100vh - 60px);
}
.modal-header-pagination-count {
    font-size: 20px;
    font-weight: 500;
}
.modalHeaderTitle {
	font-size: 20px;
	font-weight: 500;
}
.modal-footer {
	.errorMsg {
		width: 85%;
	}
}
.btn-default {
	border: 1px solid #ccc !important;
}
.btn-border {
	border: 1px solid;
	box-shadow: 0px 0px 1px 1px #e3e3e3;
}
.cursor-initial {
	cursor: inherit;
}