.d {
    &--flex {
        display: flex;
        &--center {
            @extend .d--flex;
            justify-content: center;
            align-items: center;
            &--col {
                @extend .d--flex--center;
                flex-direction: column;
            }
        }
    }
}

.h {
    &--100 {
        height: 100%;
    }
}

.w {
    &--99 {
        width: 99%;
    }
}

.ws {
    &--nowrp {
        white-space: nowrap;
    }
}
