/* You can add global styles to this file, and also import other style files */
@import "/node_modules/ngx-toastr/toastr";
@import "/node_modules/css-star-rating/css/star-rating.css";
@import "/node_modules/jsoneditor/dist/jsoneditor.min.css";

// custom style imports
@import "./assets/custom-styles/theme/main.scss";
@import "./assets/custom-styles/common-style.scss";
@import "./assets/custom-styles/color-codes.scss";
@import "./assets/custom-styles/global-styles.scss";

html {
	font-family: $font-family_1;
	height: $percent_100;
	width: $percent_100;
	padding: 0;
	margin: 0;
	overflow: hidden;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	height: $percent_100;
}
body {
	font-family: $font-family_3;
	height: $percent_100;
	width: $percent_100;
	padding: 0;
	margin: 0;
	overflow: hidden;
	-ms-overflow-style: -ms-autohiding-scrollbar;
	height: $percent_100;
	margin: 0;
}
.fullWidth {
	width: $percent_100;
}
.fullHeight {
	height: $percent_100;
}
.fullHW {
	width: $percent_100;
	height: $percent_100;
}
.VAlignChildren {
	display: flex;
	align-items: center;
}
.iconContainerSpan {
	display: inline-block;
	background-size: cover;
	height: 18px;
	vertical-align: middle;
	width: 18px;
	cursor: pointer;
}
.whiteColor {
	color: $white_color;
}
.blackColor {
	color: $black_color;
}
.greenColor {
	color: $green_color;
}
.redColor {
	color: $red_color;
}
.grayColor {
	color: $grey_color;
}
.blueColor {
	color: blue;
}
.inline-block {
	display: inline-block;
}
.display-none {
	display: none;
}
.display-table {
	display: table;
}
.hiddenSpan {
	opacity: 0.5;
}
.font-size16 {
	font-size: 16px;
	font-weight: 500;
}
.c3-line {
	stroke-width: 2px;
}
.c3-circle {
	fill: $red_color !important;
}
.c3-axis {
	stroke-width: 2px;
}
.tick {
	font-size: 12px;
}
.c3-chart-arcs-title {
	font-size: 16px !important;
}
.c3-legend-item {
	font-size: 16px;
}
.closeBtnContainer {
	display: block;
	float: right;
}
.verticalTop {
	vertical-align: top;
}
.textEllipses {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.card-box-shadow {
	box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.3);
}
.background-box-shadow {
	box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
}
.background-light-box-shadow {
	box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.2);
}
.card-light-box-shadow {
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.4);
}
.card-right-box-shadow {
	box-shadow: 2px 0px 2px rgba(0, 0, 0, 0.4);
}
.style-4 {
	&::-webkit-scrollbar-track {
		-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
		background-color: $whiteSmoke_color;
	}
	&::-webkit-scrollbar {
		width: 5px;
		background-color: $whiteSmoke_color;
	}
	&::-webkit-scrollbar-thumb {
		background-color: $lemonGrass_color;
		border: 1px solid $lemonGrass_color;
	}
}

.ddCustomClass {
	.c-label {
		width: $percent_100;
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
	.c-token {
		max-width: 92%;
	}
	label {
		text-overflow: ellipsis;
		max-width: 92%;
		overflow: hidden;
		white-space: nowrap;
	}
}
#scrolltoBottom {
	position: fixed;
	bottom: 20px;
	right: 3.2%;
	z-index: 9;
	font-size: 16px;
	border: none;
	outline: none;
	background-color: $red_color;
	color: $white_color;
	cursor: pointer;
	padding: 7px;
	width: 40px;
	height: 40px;
	border-radius: 4px;
	&:hover {
		background-color: $davyGrey_color;
	}
}
.scrollFullView {
	position: scroll;
}
.backToTopImg {
	height: 30px;
	position: absolute;
	top: 5px;
	left: 5px;
	padding: 4px;
}
.errorField {
	font-size: 12px;
	color: red;
}
.errorDiv {
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100vw;
	align-content: center;
	display: block;
	z-index: 99999999;
}
.errorMsg {
	display: flex;
	border-radius: 4px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	vertical-align: middle;
	height: auto;
	margin: 0px;
	color: $appleBlossom_color;
	letter-spacing: 2px;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	font-size: 16px;
	width: auto;
	background-color: $palePink_color;
}

.mdc-tab__text-label {
	color: rgba(0, 0, 0, 0.87) !important;
}

// spinner css
.spinner1 {
	position: fixed;
	top: 0px;
	left: 0px;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 99999999;
}
.white_background {
	margin: 0px auto;
	width: 100px;
	padding: 10px;
	padding-left: 0px;
	letter-spacing: 1px;
	text-align: center;
	position: absolute;
	top: 56%;
	left: 49%;
	z-index: 99999999999;
	color: white;
	font-size: 18px;
}
@-webkit-keyframes sk-stretchdelay {
	0%,
	40%,
	100% {
		-webkit-transform: scaleY(0.4);
	}
	20% {
		-webkit-transform: scaleY(1);
	}
}
@keyframes sk-stretchdelay {
	0%,
	40%,
	100% {
		transform: scaleY(0.4);
		-webkit-transform: scaleY(0.4);
	}
	20% {
		transform: scaleY(1);
		-webkit-transform: scaleY(1);
	}
}
::ng-deep .c-input {
	color: black;
}
.overflow-ellipse-wrap {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.lightGreen {
	background-color: #a5d6a7;
}
.lightRed {
	background-color: #ef9a9a;
}
.lightYellow {
	background-color: #fffde7;
}
.lightGrey {
	background-color: lightgrey;
}
