$inherit_color: inherit;
$color_transparent: transparent;
$waterBlue: #1c84c6;
$white_color: #ffffff;
$grey_color: grey;
$black_color: black;
$black_2_color: rgba(0, 0, 0, 0.2);
$green_color: #43a047;
$red_color: #f4511e;
$quillGrey_color: #d3d3d3;
$blackEel_color: #444444;
$onyx_color: #111;
$boulder_color: #777777;
$monsoon_color: #888888;
$dune_color: #333333;
$snowDrift_color: #f9f9f9;
$platinum_color: #e3e3e3;
$azure_color: #03a9f4;
$desertStorm_color: #f7f7f7;
$aluminium_color: #abafb1;
$pastel_Grey: #ccc;
$lemon_Grass: #999999;
$soft_Peach: #eeeeee;
$thunder_color: #2f2f2f;
$mountainMist: #959595;
$lavenderPinocchio: #e1e1e1;
$lemonGrass_color: #9a9a9a;
$softPeach_color: #eeeeee;
$success_color: #2e7d32;
$warning_color: #f57c00;
$info_color: #5e35b1;
$primary_color: #8e24aa;
$whiteSmoke_color: #f5f5f5;
$vividTangerine_color: #fba484;
$moonstoneBlue_color: #75abe2;
$paleRed_color: #d9534f;
$lightOrange_color: #f0ad4e;
$davyGrey_color: #555;
$palePink_color: #f2dede;
$cottonSeed_color: #bebdbd;
$downriver_color: #041F50;
$yaleBlue_color: #0d47a1;
$jellyfish_color: #5bc0de;
$appleBlossom_color: #a94442;
$lavenderPinocchio_color: #e0e0e0;
$greenWhite_color: #EAEAEA;
$lightgray_color: lightgray;